.container.contact__container {
  width: var(--container-width-lg);
  display: flex;
  justify-content: center;



}

.container.contact__container>a {
  padding: 1rem 0.5rem;

}

.alien_header__socials {
  font-size: 3rem;
  margin-right: 1rem;
  margin-top: -0.5rem;
}



.alien_footer__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 15rem;
  background: url('../../assets/Header.png');
  background-size: clamp(800px, 60%, 700px) auto;
  background-repeat: no-repeat;
  background-position: top;
  padding-bottom: 40rem;



}

.alien_footer__socials a {

  color: var(--color-white);

  border-radius: 0.7rem;
  display: flex;
  background-color: var(--color-bg-variant);
  border: 3px solid var(--color-bg-variant);

}

:hover.alien_footer__socials a {

  color: var(--color-bg-variant);
  background-color: var(--color-primary-variant);

}




input:focus,
textarea:focus {
  border-color: var(--color-white);
  background-color: var(--color-bg-variant);
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: var(--transition);
  justify-content: center;
  border: 3px solid var(--color-bg-variant);
  width: 35rem;
}

.contact__option h3 {
  margin-bottom: 1.2rem;
}

.contact__option:hover {
  background: var(--color-primary-variant);
  border-color: var(--color-bg-variant);
  color: var(--color-bg-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  border-color: var(--color-bg-variant);
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
  background: var(--color-bg-variant);
}

/* Form */

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: center;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}

.visuallyhidden {
  display: none;
  visibility: hidden;
}