.about__container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 2rem;
  justify-content: center;
  width: 100%;
  place-items: center;
  padding-top: 1rem;
  /* background-image: url('../../assets/Header.png');
  background-size: cover;

  background-repeat: no-repeat; */
}

.about__me {
  border-radius: 50%;
  width: 250px;
  height: 250px;
  background: var(--color-primary);
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.about__me-image {
  justify-content: center;
  width: 90%;
  align-self: center;
}

.about__me-cartoon {
  justify-content: center;
  width: 90%;
}
/* .alien__card {

}
.alien__top {

}
.alien__mid {

}
.alien__bottom {

} */

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: left;
  transition: var(--transition);
}

.alien__card {
  background: url('../../assets/Atop.png');
  background-size: cover;
  background-repeat: no-repeat;

}
.alien__card_m {
  background: url('../../assets/Atmiddle.png');
  background-size: cover;
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;

}
.alien__card_b {
  background: url('../../assets/Atbottom.png');
  padding: 2rem;
  background-size: cover;
  background-position: center; /* Centers the image */
  background-repeat: no-repeat;

}


.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about__content p {
  color: var(--color-light);
  background-color: var(--color-bg-variant, 0.5);
  padding: 1rem;
  border-radius: 1rem;
}

.about__cta-button {
  display: flex;
  justify-content: center;
}
/* ---------------- Media Queries (Med Devices) ---------------- */
@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    align-items: center;
  }
  .about__me {
    width: 250px;
    height: 250px;
    margin-bottom: 3rem;
  }
  .about__content {
    margin: 1rem 0 1.5rem;
  }
}

/* ---------------- Media Queries (Small Devices) ---------------- */
@media screen and (max-width: 600px) {
  .about__me {
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
  .about__cards {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .about__content {
    display: column;
    align-items: center;
  }
}
