.services__container {
  display: grid;

  grid-template-columns: 1fr;
  gap: 2rem;
}
.services__item-desc {
  color: var(--color-bg-variant);
}
.service {
  background: var(--color-bg-variant);
  border-radius: 0 0 2rem 2rem;

  height: auto;
  transition: var(--transition);
  font-weight: 500;
}

.alien-img{
  width:50%;

}
.test {
  background-color: blue;
}

.service:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.service__head {
  background: var(--color-primary);
  color: var(--color-bg-variant);
  font-size: 1rem;
  text-align: center;
  padding-top: 0.5rem;
  border-radius: 0 0 1rem 1rem;
}

.service__list {
  padding: 1rem;
}

.service__list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
  justify-content: flex-start;
}

.service__list-icon {
  color: var(--color-primary);
}

.service__list p {
  font-size: 0.9rem;
}

/* ---------------- Media Queries (Med Devices) ---------------- */
@media screen and (max-width: 1024px) {
  .services__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .service {
    height: auto;
  }
}

/* ---------------- Media Queries (Small Devices) ---------------- */
@media screen and (max-width: 600px) {
  .services__container {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }


}